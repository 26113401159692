import React, { useEffect, useContext } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";
import Media from "react-media";

// Context
import WindowWidthContext from "../../components/context/window-width";
import StoreContext from "../../components/context/store";
import { FooterColor } from "../../components/context/footer-color";

// Components
import Layout from "../../components/account/Layout";
import { TradeAccountEnquiryForm } from "../../components/forms/trade-account-enquiry-form";
import { AccountNavigation } from "../../components/account/account-navigation";
import { MobileAccountNavigation } from "../../components/account/mobile-account-navigation";

import {
	Page,
	AccountContainer,
	ContentContainer,
} from "../../components/trade/trade-components";
import { tertiary } from "../../components/utils/colors";

const CUSTOMER_INFO = gql`
	query($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			email
			firstName
			lastName
			tags
			defaultAddress {
				id
				address1
				address2
				city
				phone
				lastName
				firstName
				country
				name
				zip
			}
		}
	}
`;

const IntroText = styled.div`
	display: inline;
`;

const CustomerDetails = styled.div`
	& p {
		margin: 0;
	}

	& p.email {
		margin: 0 0 1em 0;
	}
`;

const FormContainer = styled.div`
	background-color: ${tertiary};
	padding: 60px 0 0 0;

	width: 100%;

	& .account-container {
		margin: 0 0 0 150px;

		@media (max-width: 1024px) {
			margin: 0;
		}
	}
`;

const Index = ({ data }) => {
	const windowWidth = useContext(WindowWidthContext);
	const { customerAccessToken } = useContext(StoreContext);
	const accountData = data.prismicAccounts.data;
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(`#fff`);
	}, []);

	return (
		<Layout>
			{customerAccessToken !== null && (
				<Query
					query={CUSTOMER_INFO}
					variables={{
						customerAccessToken: customerAccessToken.accessToken,
					}}
				>
					{({ loading, error, data }) => {
						if (loading) {
							return (
								<Page>
									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation
												position={`relative`}
												top={0}
												data={data}
											/>
										)}
									/>
									<AccountContainer>
										<ContentContainer>
											<p className="margin-0">Fetching</p>
										</ContentContainer>
									</AccountContainer>
								</Page>
							);
						}
						if (error) {
							return (
								<Page>
									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation position={`relative`} top={0} />
										)}
									/>
									<AccountContainer>
										<ContentContainer>
											<p className="margin-0">
												Error loading page. Please try refreshing.
											</p>
										</ContentContainer>
									</AccountContainer>
								</Page>
							);
						}

						return (
							<Page>
								<Helmet title={`Account | Christian Watson`} />

								<Media
									queries={{
										medium: "(min-width: 1025px)",
									}}
									defaultMatches={{
										medium: windowWidth === 1025,
									}}
									render={() => (
										<AccountNavigation position={`relative`} top={0} />
									)}
								/>

								<Media
									queries={{
										medium: "(max-width: 1024px)",
									}}
									defaultMatches={{
										medium: windowWidth === 1024,
									}}
									render={() => (
										<MobileAccountNavigation position={`relative`} top={0} />
									)}
								/>

								<AccountContainer>
									<ContentContainer>
										<div className="text-column">
											<h1 className="caps">Your Account</h1>
											<IntroText>
												<p>
													{accountData.intro_text.text}{" "}
													{data.customer !== null && data.customer.firstName},{" "}
													{accountData.text.text}
												</p>
											</IntroText>
										</div>
										{/* {data.customer !== null && (
											<div className="text-column">
												<h2 className="caps">Your Details</h2>
												<CustomerDetails>
													<p className="name">
														{data.customer.firstName} {data.customer.lastName}
													</p>
													<p className="email">{data.customer.email}</p>
													<p>{data.customer.defaultAddress.address1}</p>
													<p>{data.customer.defaultAddress.address2}</p>
													<p>
														{data.customer.defaultAddress.city}{" "}
														{data.customer.defaultAddress.zip}
													</p>
													<p>{data.customer.defaultAddress.country}</p>
													<p>{data.customer.defaultAddress.phone}</p>
												</CustomerDetails>
											</div>
										)} */}
									</ContentContainer>
								</AccountContainer>

								<FormContainer>
									<AccountContainer className="account-container">
										<ContentContainer>
											<TradeAccountEnquiryForm
												content={accountData.contact_form.document}
											/>
										</ContentContainer>
									</AccountContainer>
								</FormContainer>
							</Page>
						);
					}}
				</Query>
			)}
		</Layout>
	);
};

export const query = graphql`
	{
		prismicAccounts {
			data {
				intro_text {
					text
				}
				text {
					text
				}
				contact_form {
					document {
						... on PrismicEnquiryForm {
							id
							data {
								form_title {
									html
								}
								form_text {
									html
								}
								form_disclaimer_text {
									html
								}
								form_message_placeholder
								dropdown_options {
									dropdown_option
									linked_email_address
								}
								additional_form_fields {
									additional_form_field
								}
								enable_file_upload
								file_upload_text {
									html
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default Index;
